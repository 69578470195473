

































































































import { Component, Vue } from "vue-property-decorator";
@Component
export default class IllData extends Vue {
  dialogVisible = false;
  formData = {
    patient_id: "",
    operation_status: 1, // 是否做过手术 1是 0否
    operation_name: "", // 手术类型
    operation_year: "", // 手术年份
    take_medication: "", // 是否服药
    patient_name: "", // 患者真实姓名
    patient_sex: "", // 性别
    patient_type: "", // 患者类别（状态码）
    patient_tel: "", // 患者电话
    patient_disease: "", // 当前疾病
    disease_date: "2", // 患病时间
    other_disease_history: "", // 其他疾病史
    medication_history: "", // 服药历史
    clinic_hospital: "", // 就诊医院
    clinic_office: "", // 就诊科室
    record_url: "", //
    createdate: "",
    age: "", // 年龄
    inquiry_amount: "", // 图文问诊数量
    video_amount: "", // 视频问诊数量
    appointment_amount: "", // 程控数量
    nickname: "", // 用户昵称
    disease_ext: "", // 疾病症状补充说明
    avatar: "",
    remark: "", // 患者备注
  };
  imgTrans_url = [];
  view_img = "";
  view(ele: any) {
    this.dialogVisible = true;
    this.view_img = ele;
  }
}
